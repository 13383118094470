<template>
  <div class="hello">
  <ul>
    <li v-for="pic in pics" v-bind:key="pic">
      <img :src="pic"/>
    </li>
  </ul>
  </div>
</template>

<script>
import { db } from '@/services/db';
import { getLog } from "@/services/log";
let log = getLog("allpictures");

export default {
  name: 'allpictures',
  components: {
  },
  data() {
    return {
      pics: null,
    };
  },
  mounted() {
    log.log("mounted");
    let that = this;
    db.collection("LiveUsers")
      //.where("test", "==", null)
      .get()
      .then(querySnapshot => {
        const docs = querySnapshot.docs.map(doc => [ doc.data().picture0, doc.data().picture1 ]);
        let pics = docs.flat();
        that.pics = pics.filter(p => p != "https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png");
      }
    );
  },
  methods: {
  }
}
</script>

<style scoped>
li {
  max-height: 500px;
  display: inline-block;
}
</style>